import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes, array, bool } from 'prop-types';
import { Form, Input, Button, Badge, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialCheckbox from '../../shared/components/material-checkbox/MaterialCheckbox';
import DataTableProgressComponent from '../../shared/components/widget/DataTableProgressComponent';
import DataTableNoDataComponent from '../../shared/components/widget/DataTableNoDataComponent';
import SimpleLineIcon from 'react-simple-line-icons';
import DropdownList from 'react-widgets/lib/DropdownList';
import 'react-widgets/dist/css/react-widgets.css';
import Combobox from 'react-widgets/lib/Combobox';
import { debounce } from 'lodash';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import './Modal.css';
import './Customers.css';
import CustomerShow from './CustomerShow';

import memoize from 'memoize-one';
import DataTable, { createTheme } from 'react-data-table-component';

import { checkAuth } from '../../actions/sessionActions';
import { getBanks, getBanksList } from '../../actions/bankActions';
import { getCustomer, getCustomers, deleteCustomer, getCustomersCsv } from '../../actions/customerActions';

import { SubmissionError } from 'redux-form'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Redux Store
import configureStore from '../../shared/redux/configureStore';
import { deleteTransaction } from '../../actions/transactionActions';
// Configuring Redux Store
const store = configureStore(window.initialState);

const columns = memoize((handleEditClick, handleShowClick, handleDeleteClick) => [
  {
    name: 'ID',
    selector: 'id',
    sortable: true
  },
  {
    name: 'Username',
    selector: 'email',
    sortable: true
  },

  {
    name: 'Created at',
    selector: 'created_at',
    sortable: true,
    cell: row => <span>{row?.created_at?.split('T')[0]}</span>,
  },
  {
    name: 'Alias',
    selector: 'alias',
    sortable: true,
    cell: row => <span>-</span>,
  },
  {
    name: 'First IBAN',
    selector: 'iban',
    sortable: true,
    cell: row => <span>{row?.bank_account?.ibans[0] || '-'}</span>,
  },
  {
    name: 'Status',
    selector: 'active',
    sortable: false,
    cell: row => <span>{row.active == true ? 'Active' : 'Inactive'}</span>,
    center: true,
    grow: 1
  },
  {
    name: 'Actions',
    sortable: false,
    left: true,
    cell: (row) => <div>
      <button className="btn btn-sm btn-light mr-2 view" onClick={handleEditClick} id={row.id} data-id={row.id}><i className="icon-eye" onClick={handleEditClick} id={row.id} data-id={row.id}></i></button>
      <button className="btn btn-sm btn-light mr-2" onClick={handleEditClick} id={row.id} data-id={row.id}><i className="icon-pencil" onClick={handleEditClick} id={row.id} data-id={row.id}></i></button>
      <button className="btn btn-sm btn-light mr-2" onClick={handleDeleteClick} id={row.id}><i className="icon-trash" id={row.id}></i></button>
      <button className="btn btn-sm btn-light mr-2" onClick={handleShowClick} id={row.id} data-id={row.id}><i className="icon-chart" onClick={handleShowClick} id={row.id} data-id={row.id}></i></button>
    </div>,
    ignoreRowClick: true,
    grow: 1
  }
]);


const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

class Customers extends Component {

  constructor(props) {
    super(props);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleShowClick = this.handleShowClick.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleBankChange = this.handleBankChange.bind(this);
    this.onSearchBanks = debounce(this.onSearchBanks.bind(this), 300);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    var bankId = ((new URLSearchParams(window.location.search)).get("bank_id"));
    this.state = {
      data: [],
      loading: false,
      totalRows: 0,
      perPage: 10,
      sort: false,
      search: null,
      modalOpen: false,
      customer: null,
      customerId: null,
      modalTitle: null,
      error: null,
      banks: [],
      selectedBank: bankId == null ? null : parseInt(bankId),
      showSearch: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    console.log('componentDidMount');
    this.setState({ loading: false, data: [] });
    getBanksList().then(({ items }) => this.setState(item => ({ ...item, banks: items })));

    if (this.state.selectedBank != null) {
      this.setState({ showSearch: true }, function () {
        this.fetchCustomers();
      });
    }
    /* JWT is stored in memory */
    /*
    const {session} = this.props
    if(typeof(session.jwt)=='undefined') {
      sessionStorage.removeItem('jwt');
      this.setState({session: null});
      this.props.history.push({pathname: '/login'});
      window.location.href="/login";
    }
    */
  }

  handleQueryChange(event) {
    console.log('handleQueryChange');
    console.log(event.target.value)
    var search = {}
    search['search[name]'] = encodeURIComponent(event.target.value);
    this.setState({ search: search });
  }

  handleFindCustomers = async (e) => {
    // e.preventDefault();
    console.log('handleFindCustomers');
    console.log(this.state.search);
    console.log(this.state.selectedBank);

    const currentPath = this.props.location.pathname;
    const currentQuery = this.props.location.search;
    // Додаємо GET-параметр


    if (this.state.search && this.state.search.toString().length > 1) {
      this.setState({ loading: true });
      var params = {}
      params.per_page = this.state.perPage;
      params.bank_id = this.state.selectedBank;

      if (this.state.sort) {
        Object.assign(params, this.state.sort)
      }
      if (this.state.search) {
        Object.assign(params, this.state.search)
      }

      this.props.history.push(`${currentPath}?${new URLSearchParams({ ...{ 'bank_id': this.state.selectedBank }, ...this.state.search }).toString()}`);

      store.dispatch(getCustomers(params)).then((result) => {
        this.setState({
          loading: false,
          data: result.payload.items,
          totalRows: result.payload.meta.total_items
        });

      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
        this.setState({
          error: error,
          modalOpen: true
        });
      });
    }
  }

  handlePageChange = async page => {
    console.log('handlePageChange');
    this.setState({ loading: true });
    const { perPage } = this.state;
    var params = {}
    params.per_page = perPage;
    params.page = page;
    params.bank_id = this.state.selectedBank;
    if (this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if (this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getCustomers(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        totalRows: result.payload.meta.total_items
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });

  }

  handlePerRowsChange = async (perPage, page) => {
    console.log('handlePerRowsChange');
    this.setState({ loading: true });
    var params = {}
    params.per_page = perPage;
    params.page = page;
    params.bank_id = this.state.selectedBank;
    if (this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if (this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getCustomers(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        totalRows: result.payload.meta.total_items,
        perPage: perPage,
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleSort = async (column, sortDirection) => {
    console.log('handleSort');
    this.setState({ loading: true });
    var params = {}
    params.per_page = this.state.perPage;
    params.bank_id = this.state.selectedBank;
    var sort = {}
    sort['order[' + column.selector + ']'] = sortDirection;
    Object.assign(params, sort)
    if (this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getCustomers(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        totalRows: result.payload.meta.total_items,
        sort: sort
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  };

  handleEditClick(event) {
    if (event.target.closest('button').classList.contains('view')) {
      this.props.history.push({
        pathname: '/customers/view/' + event.target.dataset.id + '/' + this.state.selectedBank,
        search: "?" + new URLSearchParams({ bank_id: this.state.selectedBank }).toString()
      });
    } else {
      this.props.history.push({
        pathname: '/customers/edit/' + event.target.dataset.id + '/' + this.state.selectedBank,
        search: "?" + new URLSearchParams({ bank_id: this.state.selectedBank }).toString()
      });
    }
  }

  handleShowClick(event) {
    console.log('handleShowClick');
    store.dispatch(getCustomer({ id: event.target.dataset.id, bank_id: this.state.selectedBank })).then((result) => {
      if (typeof (result) != 'undefined') {
        console.log(result);
        var customer = result.payload;
        this.setState({
          customer: customer,
          customerId: customer.id,
          modalTitle: 'Customer details',
          modalOpen: true
        })
        //this.setState({modalOpen: true});
      }
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleDeleteClick(event) {
    console.log('handleDeleteClick');
    if (window.confirm('Are you sure you want to delete this review?')) {
      store.dispatch(deleteCustomer(this.state.selectedBank, event.target.id)).then((result) => {
        if (typeof (result) != 'undefined') {
          var review = result.payload;
          window.location.reload();
        }
      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
        this.setState({
          error: error,
          modalOpen: true
        });
      });
    }
  }


  onSearchInputChange = (evt, field) => {
    function getQueryParams() {
      const params = new URLSearchParams(window.location.search);
      const queryParams = {};

      for (const [key, value] of params.entries()) {
        queryParams[key] = value;
      }

      return queryParams;
    }

    const queryParamsObject = getQueryParams();
    const search = Object.assign({}, this.state.search, queryParamsObject);
    console.log(search, 'searchsearchsearch')
    console.log('evt: ', evt)
    console.log('field: ', field)

    if (typeof (field) != 'undefined') {
      if (field == 'active') {
        if (evt == 'Active') {
          var evt = {
            target: { name: 'active', value: '', checked: true }
          }
        }
        if (evt == 'Inactive') {
          var evt = {
            target: { name: 'active', value: '', checked: false }
          }
        }
        if (evt == 'Status') {
          var evt = {
            target: { name: 'active', value: '', checked: null }
          }
        }
      }
    }

    if (evt.target.value != '') {
      search['search[' + evt.target.name + ']'] = encodeURIComponent(evt.target.value).trim();
    }
    else if (evt.target.name == 'active' && evt.target.checked != null) {
      search['search[' + evt.target.name + ']'] = evt.target.checked;
    }
    else if (evt.target.name == 'active' && evt.target.checked == null) {
      delete search['search[' + evt.target.name + ']'];
    }
    else {
      delete search['search[' + evt.target.name + ']'];
    }
    console.log(search)
    this.setState({ search });

    setTimeout(() => this.handleFindCustomers(evt))
  }

  handleModalOpen() {
    this.setState({ modalOpen: true });
  }

  handleModalClose() {
    this.setState({ modalOpen: false })
  }

  handleBankChange(e) {
    console.log('---handleBankChange');
    if (e.id != '' && e.id > 0) {
      this.setState({ selectedBank: e.id, showSearch: true }, function () {
        this.fetchCustomers();
      });
      this.props.history.push({
        pathname: '/customers/customers/' + e.id,
        search: "?" + new URLSearchParams({ bank_id: e.id }).toString()
      });
    }
    else {
      this.setState({
        data: [],
        loading: false,
        totalRows: 0,
        perPage: 10,
        sort: false,
        search: null,
        showSearch: false,
        selectedBank: null
      });
    }
  }

  onSearchBanks = (value) => {
    if (typeof value === 'object') {
      this.handleBankChange(value);
    } else {
      getBanksList({ 'search[name]': value }).then(({ items }) => {
        if (JSON.stringify(this.state.banks) !== JSON.stringify(items)) {
          this.setState({ banks: items });
        }
      });
    }
  }

  async fetchCustomers(csv) {

    var params = {}
    params.per_page = this.state.perPage;
    params.bank_id = this.state.selectedBank;
    var sort = {}
    if (this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if (this.state.search) {
      Object.assign(params, this.state.search)
    }

    function getQueryParams() {
      const params = new URLSearchParams(window.location.search);
      const queryParams = {};

      for (const [key, value] of params.entries()) {
        queryParams[key] = value;
      }

      return queryParams;
    }

    const queryParamsObject = getQueryParams();
    console.log(queryParamsObject);

    params = { ...params, ...queryParamsObject }

    if (csv) {
      const request = await getCustomersCsv(params);

      const csvFile = await request

      const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });


      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `csvFile-bank_id=${params['bank_id']}.csv`;

      document.body.appendChild(a);
      a.click();


      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      console.log(await request)
    } else {
      store.dispatch(getCustomers(params)).then((result) => {
        this.setState({
          loading: false,
          data: result.payload.items,
          totalRows: result.payload.meta.total_items,
          sort: sort
        });
      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
        this.setState({
          error: error,
          modalOpen: true
        });
      });
    }

  }


  render() {

    const session = this.props.session
    if (typeof (session.forceLogout) != "undefined" && session.forceLogout == 1) {
      this.setState({ session: null });
      window.location.href = "/login";
    }

    const { loading, data, totalRows } = this.state;
    console.log('totalRows')
    console.log(totalRows)
    const { banks } = this.props;

    const handleChange = (state) => {
      // You can use setState or dispatch with something like Redux so we can use the retrieved data
      console.log('Selected Rows: ', state.selectedRows);
    };

    const banksArr = this.state.banks.map(bank => ({ id: bank.id, name: bank.name }));

    function getQueryParams() {
      const params = new URLSearchParams(window.location.search);
      const queryParams = {};

      for (const [key, value] of params.entries()) {
        queryParams[key] = value;
      }

      return queryParams;
    }

    const queryParamsObject = getQueryParams();
    console.log(queryParamsObject);

    return (
      <div className="animated fadeIn mt-5">
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          id="modal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalOpen}>
            <div className="material-modal-content">
              <h4 id="transition-modal-title" className="mb-4">{this.state.modalTitle} <i className="cil-x cursor-pointer float-right" onClick={this.handleModalClose}></i></h4>
              <div id="transition-modal-description">
                {this.state.error != null
                  ? <div>{this.state.error.toString()}</div>
                  : <CustomerShow customer={this.state.customer} customerId={this.state.customerId} bankId={this.state.selectedBank} />
                }
              </div>
            </div>
          </Fade>
        </Modal>
        <Row >
          <Col xs="12" lg="2">
            {/* <DropdownList filter
              placeholder="Choose Bank"
              data={[{id:'0', name: ''}].concat(banksArr)}
              valueField="id"
              textField="name"
              defaultValue={{id: this.state.selectedBank!=null ? this.state.selectedBank: 0, name: (this.state.selectedBank!=null && banksArr.length>0) ? banksArr.find(b => b.id == this.state.selectedBank).name : ''}}
              onChange={this.handleBankChange}
            /> */}

            <Combobox
              placeholder="Choose Bank"
              data={[].concat(banksArr)}
              valueField="id"
              textField="name"
              defaultValue={{ id: this.state.selectedBank != null ? this.state.selectedBank : 0, name: (this.state.selectedBank != null && banksArr?.length > 0) ? banksArr?.find(b => b.id == this.state.selectedBank)?.name : '' }}
              type="select"
              filter={false}
              asterisk="*"
              className="mb-4"
              onChange={this.onSearchBanks}
            />
          </Col>

          {data.length > 0 ? <Col xs="12" lg="10" className='d-flex justify-content-end align-items-baseline'>
            <button className='btn btn-secondary' type="button" onClick={() => this.fetchCustomers('csv')}>Export CSV</button>
          </Col> : null}

          <Col className='mt-2' xs="12" lg="12">
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center justify-content-between">
                  <div><i className="fa fa-align-justify"></i> Customers list</div>
                  {this.state.showSearch &&
                    <div>
                      <Form className="form-inline float-right" onSubmit={this.handleFindCustomers}>

                        <Input type="search" name="id" className="mr-2"
                          placeholder="User ID"
                          defaultValue={queryParamsObject['search[id]']}
                          onChange={this.onSearchInputChange}
                        />

                        <Input type="search" name="iban" className="mr-2"
                          placeholder="IBAN"
                          defaultValue={queryParamsObject['search[iban]']}
                          onChange={this.onSearchInputChange}
                        />

                        <Input type="search" name="username" className="mr-2"
                          placeholder="User Name"
                          defaultValue={queryParamsObject['search[username]']}
                          onChange={this.onSearchInputChange}
                        />

                        <Input type="search" name="email" className="mr-2"
                          placeholder="User Email"
                          defaultValue={queryParamsObject['search[email]']}
                          onChange={this.onSearchInputChange}
                        />

                        <DropdownList
                          name="active"
                          onChange={(e) => this.onSearchInputChange(e, 'active')}
                          data={['Status', 'Active', 'Inactive']}
                          placeholder="Status"
                          className="status-dropdown"
                          defaultValue={queryParamsObject['search[status]']}
                        />
                        <a onClick={(e) => this.handleFindCustomers(e)} className="search-btn ml-1 cursor-pointer btn btn-outline-secondary"><i className="icon-magnifier"></i></a>
                      </Form>
                    </div>
                  }
                </div>
              </CardHeader>
              <CardBody>
                <DataTable
                  noHeader={false}
                  columns={columns(this.handleEditClick, this.handleShowClick, this.handleDeleteClick)}
                  onSelectedRowsChange={this.updateState}
                  data={data}
                  theme="solarized"
                  progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  onSort={this.handleSort}
                  sortServer
                  selectableRows
                  selectableRowsComponent={MaterialCheckbox}
                  selectableRowsComponentProps={selectProps}
                  onSelectedRowsChange={handleChange}
                  progressComponent={<DataTableProgressComponent />}
                  noDataComponent={<DataTableNoDataComponent />}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

Customers.propTypes = {
  customers: PropTypes.array,
  data: PropTypes.array,
  banks: PropTypes.array,
  session: PropTypes.any
}

function mapStateToProps(state) {
  console.log('STATE')
  console.log(state);
  return {
    customers: state?.customers,
    data: state?.customers?.customers?.items,
    banks: (typeof (state.banks) !== 'undefined' && typeof (state.banks.result) !== 'undefined') ? state.banks.result.items : [],
    session: state.session
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({ getCustomer, getCustomers, getBanks, checkAuth }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customers)