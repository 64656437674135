import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import DropdownList from 'react-widgets/lib/DropdownList';
import { Card, CardBody, CardHeader, Col, Form, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { getBanks, getBanksList } from '../../actions/bankActions';
import { createPayouts } from '../../actions/paymentActions';
import Snackbar from '@material-ui/core/Snackbar';
import Combobox from 'react-widgets/lib/Combobox';
import { debounce } from 'lodash';

function PayoutsCreate() {
    const [list, setList] = useState({});
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const input = useRef();
    const [bankList, setBankList] = useState([]);

    useEffect(() => {
        getBanksList().then(({ items }) => setBankList(items));
    }, []);

    const handleList = useCallback((value) => {
        setList(value);
    }, [list]);

    const onSearchBanks = debounce((value) => {
        if (typeof value === 'object') {
            handleList(value);
        } else {
            getBanksList({ 'search[name]': value }).then(({ items }) => {
                if (JSON.stringify(bankList) !== JSON.stringify(items)) {
                    setBankList(items);
                }
            });
        }
    }, 300);

    const formSubmit = useCallback(async (event) => {
        const reference = input?.current?.value || ''
        event.preventDefault();
        const data =
        {
            bank_id: list.id,
            ...(reference && { reference })
        }

        const request = await createPayouts(data);

        const { transactions = {}, bank = {}, offer = {}, id = null } = request

        console.log(request);

        if (id) {
            await setError('');
            await setSuccess(true);

            return setTimeout(() => setSuccess(false), 2000);
        }

        if (Object.values(transactions).length > 0 && Object.values(bank).length > 0) return setError('No transactions available to create a payout');

        if (Object.values(offer).length > 0) return setError('No transactions available to create a payout');

        if (Object.values(transactions).length > 0) return setError('No transactions available to create a payout');

        if (Object.values(bank).length > 0) return setError('No transactions available to create a payout');
    }, [list, input]);

    return (
        <div className={'col-12 col-lg-12'}>

            <Card>
                <CardHeader>
                    <i className="fa fa-align-justify" />
                    Create Payout
                </CardHeader>
                <CardBody>
                    <Form onSubmit={formSubmit}>
                        <Row>
                            <Col xs="12" lg="12">
                                <Combobox
                                    placeholder="Choose Bank"
                                    data={[].concat(bankList)}
                                    valueField="id"
                                    textField="name"
                                    type="select"
                                    filter={false}
                                    asterisk="*"
                                    className="mb-4"
                                    onChange={onSearchBanks}
                                />
                            </Col>

                            <Col xs={12}>
                                Creating a new payout will link all withdrawals with it and mark them as paid
                            </Col>

                            {/*<Col xs="12" lg="6">*/}
                            {/*    <input*/}
                            {/*        ref={input}*/}
                            {/*        name={'search'}*/}
                            {/*        type="text"*/}
                            {/*        placeholder={'Reference optiomal'}*/}
                            {/*        className="form-control"*/}
                            {/*    />*/}
                            {/*</Col>*/}

                            <Col xs="12" lg="6">
                                <button disabled={!Object.keys(list).length > 0} type="submit" className="btn btn-primary mt-5 mb-2 d-block">Submit</button>
                                {
                                    error &&
                                    <span className={'text-danger'}>
                                        {error}
                                    </span>
                                }
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>

            <Snackbar open={success} autoHideDuration={2000} message="Saved" onClose={() => setSuccess(true)}
                action={
                    <>
                        <a className="text-white cursor-pointer mr-2" aria-label="close" onClick={() => setSuccess(false)}><i className="cil-x"></i></a>
                    </>
                }
            />
        </div>
    );
}

const mapStateToProps = state => ({
    bankList: state?.banks?.result?.items
});

const mapDispatchToProps = {
    getBanks
};

const ConnectedPaymentsCreate = connect(
    mapStateToProps,
    mapDispatchToProps
)(PayoutsCreate);

export default memo(ConnectedPaymentsCreate);
