import React, {Component} from 'react';
import debounce from 'lodash/debounce';
import { PropTypes, array, bool } from 'prop-types';
import Combobox from 'react-widgets/lib/Combobox';
import 'react-widgets/dist/css/react-widgets.css';
import { renderCombobox } from '../form-field/ReduxFormFields';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

class BrandCombobox extends Component { 
	
	constructor(props) {
	  super(props);
	  this.state = {
      open: false,
      create: false,
      search: null,
      brands: null,
      selectedBank: '',
      brandSearchResult: null
    }

    this.handleBrandChange = this.handleBrandChange.bind(this);
	}

  handleBrandChange(selectedBankName) {
  	console.log('--searchable-bank-change');
  	console.log(selectedBankName);
  	const selectedBank = selectedBankName=='All banks' ? '' : selectedBankName
    this.setState({selectedBank});

    this.props.onChange({
      target: {name: 'name', value: selectedBank}
    });
  }

	render() {
		console.log('---render');

    var selectedBank = '';
    if(this.state.selectedBank==null) {
      selectedBank = '';
    }
    else if(this.state.selectedBank!='') {
      selectedBank = this.state.selectedBank;
    }

		return (
			<Combobox
			  name="brand"
			  type="select"
			  data={['All banks', ...this.props.banks]}
			  defaultValue={selectedBank}
			  valueField="id"
			  textField="name"
			  filter={false}
			  className={`p-0 pr-2 ${typeof(this.props.className)!='undefined' ? this.props.className : ''}`}
			  onChange={(event) => this.handleBrandChange(event)}
			  disabled={[{id:0, name: '...'}]}
			  placeholder="All Banks"
			  asterisk="*"
			/>
		)
	}

}

function mapStateToProps(state) {
	console.log('STATE')
	console.log(state);
  return {
    session: state.session
  }
}

export default connect(
  mapStateToProps,
)(BrandCombobox)
