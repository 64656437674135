import React, { memo, useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Form, Input, Row } from "reactstrap";
import BrandCombobox from "../../shared/components/searchable-field/BrandCombobox";
import DropdownList from "react-widgets/lib/DropdownList";
import DataTable from "react-data-table-component";
import MaterialCheckbox from "../../shared/components/material-checkbox/MaterialCheckbox";
import DataTableProgressComponent from "../../shared/components/widget/DataTableProgressComponent";
import DataTableNoDataComponent from "../../shared/components/widget/DataTableNoDataComponent";
import { getClicks } from "../../actions/usersActions";
import { getBanks } from "../../actions/brandUpdateActions";
import { getBanksList } from "../../actions/bankActions";

import memoize from "memoize-one";
import { debounce } from "lodash";
import Combobox from "react-widgets/lib/Combobox";

function Click(props) {
  const [banksList, setBanksList] = useState([]);
  const [clicks, setClicks] = useState({ items: [], meta: {} });
  const [bankList, setBankList] = useState([]);
  const [pagination, setPagination] = useState({page: 1, per_page: 10})
  const [bankId, setBankId] = useState(null)

  const getClicksAction = useCallback(async (params) => {
    const request = await getClicks(params);
    setClicks(await request);
  }, []);

  const handleBankChange = useCallback(({ id }) => {
    setBankId(id)
    // getClicksAction({ bank_id: id, page: 1, per_page: 10 });
  }, [bankId]);


  useEffect(() => {
    if(bankId) {
      getClicksAction({ bank_id: bankId, ...pagination });
    }
  }, [pagination?.page, pagination['per_page'], bankId]);

  const getBanksAction = useCallback(async () => {
    const request = await getBanks();

    setBanksList(await request);
  }, []);

  const onSearchBanks = debounce((value) => {
    if (typeof value === "object") {
      handleBankChange(value);
    } else {
      getBanksList({ "search[name]": value }).then(({ items }) => {
        if (JSON.stringify(bankList) !== JSON.stringify(items)) {
          setBankList(items);
        }
      });
    }
  }, 300);

  const columns = memoize(() => [
    {
      name: "ID",
      selector: "id",
      sortable: false,
      grow: 0,
    },
    {
      name: "user ID",
      selector: "id",
      sortable: false,
      grow: 0,
      cell: (row) => row?.customer_id
    },
    {
      name: "Date",
      selector: "created_at",
      sortable: false,
      grow: 1,
      cell: (row) => row?.created_at?.split("T")[0],
    },
    {
      name: "Partner",
      selector: "brand",
      sortable: false,
      grow: 1,
      cell: (row) => row.brand.name,
    },
  ]);

  useEffect(() => {
    getBanksList().then(({ items }) => setBankList(items));
  }, []);
  
  const { items, meta } = clicks;

  return (
    <Row>
      <Col xs="12" lg="12">
        <Row>
          <Col xs="12" lg="2">
            <Combobox
              placeholder="Choose Bank"
              data={[].concat(bankList)}
              valueField="id"
              textField="name"
              type="select"
              filter={false}
              asterisk="*"
              className="mb-4"
              onChange={onSearchBanks}
            />
          </Col>
        </Row>
        <Card>
          <CardHeader>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <i className="fa fa-align-justify"></i> Clicks list
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <DataTable
              noHeader={false}
              data={items}
              columns={columns()}
              theme="solarized"
              progressPending={false}
              pagination
              paginationServer
              paginationTotalRows={meta?.total_items}
              paginationRowsPerPageOptions={[10, 15, 30, 50]}
              onChangeRowsPerPage={(e) => setPagination(item => ({...item, per_page: e}))}
              onChangePage={(e) => setPagination(item => ({...item, page: e}))}
              // onSort={this.handleSort}
              sortServer
              selectableRows
              selectableRowsComponent={MaterialCheckbox}
              // selectableRowsComponentProps={selectProps}
              // onSelectedRowsChange={handleChange}
              progressComponent={<DataTableProgressComponent />}
              noDataComponent={<DataTableNoDataComponent />}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default memo(Click);
