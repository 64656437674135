import React, {Component} from 'react';
import {PropTypes, array, bool} from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Badge, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialCheckbox from '../../shared/components/material-checkbox/MaterialCheckbox';
import SimpleLineIcon from 'react-simple-line-icons';
import BankCombobox from '../../shared/components/searchable-field/BankCombobox';
import DropdownList from 'react-widgets/lib/DropdownList';
import 'react-widgets/dist/css/react-widgets.css';
import Combobox from 'react-widgets/lib/Combobox';
import { debounce } from 'lodash';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import './Modal.css';
import './Banks.css';
import BankShow from './BankShow';

import memoize from 'memoize-one';
import DataTable, { createTheme } from 'react-data-table-component';

import { checkAuth } from '../../actions/sessionActions';
import { getBank, getBanks } from '../../actions/bankActions';

import { SubmissionError } from 'redux-form'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Redux Store
import configureStore from '../../shared/redux/configureStore';
import CategoryCombobox from "../../shared/components/searchable-field/CategoryCombobox";
// Configuring Redux Store
const store = configureStore(window.initialState);

const columns = memoize((handleEditClick, handleShowClick) => [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    center: true,
    grow: 0
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    grow: 2
  },
  {
    name: 'Identifier',
    selector: 'identifier',
    sortable: false,
    right: false,
    grow: 1
  },
  {
    name: 'Online Plus',
    selector: 'onlineplus',
    sortable: false,
    right: false,
    grow: 1
  },
  {
    name: 'Regio Plus',
    selector: 'regioplus',
    sortable: false,
    right: false,
    grow: 1
  },
  {
    name: 'Access',
    selector: 'access',
    sortable: false,
    right: false,
    grow: 1
  },
  {
    name: 'URL',
    selector: 'url',
    sortable: false,
    cell: row => <a href={row.url} taget="_blank">{row.url ? row.url : 'Not set'}</a>,
    grow: 2
  },
  {
    name: 'Status',
    selector: 'active',
    sortable: false,
    cell: row => <span>{row.active==true ? 'Active' : 'Inactive'}</span>,
    center: true,
    grow: 1
  },
  {
    name: 'Actions',
    sortable: false,
    left: true,
    cell: (row) => <div>
                      <button className="btn btn-sm btn-light mr-2" onClick={handleEditClick} id={row.id}><i className="icon-pencil" onClick={handleEditClick} id={row.id}></i></button>
                      <button className="btn btn-sm btn-light mr-2" onClick={handleShowClick} id={row.id}><i className="icon-chart" onClick={handleShowClick} id={row.id}></i></button>
                    </div>,
    ignoreRowClick: true
  }
]);


const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

class Banks extends Component {

  state = {
    data: [],
    banks: [],
    loading: false,
    totalRows: 0,
    perPage: 10,
    sort: false,
    search: null,
    modalOpen: false,
    bank: null,
    modalTitle: null,
    error: null
  };
  
  constructor(props) {
    super(props);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleShowClick = this.handleShowClick.bind(this);
    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.onSearchBanks = debounce(this.onSearchBanks.bind(this), 300);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
  }
  
  componentDidMount(props) {
    this.setState({ loading: true });
    console.log('componentDidMount');
    console.log('---accounts')
    console.log(this.props.accounts);

    const { perPage } = this.state;
    console.log(this.state,'this.state')
    console.log(perPage);
    var params = {}
    params.per_page = perPage;
    params.bankPage = true;
    store.dispatch(getBanks(params)).then((result) => {
      this.setState({
        data: result.payload.items,
        banks: (result.payload.items || []).map(item => (item || {}).name),
        sortData:  (result.payload.items || []).map(item => (item || {}).name),
        totalRows: result.payload.meta.total_items,
        loading: false,
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalTitle: 'Error!',
        modalOpen: true
      });
    }); 
    /* JWT is stored in memory */
    /*
    const {session} = this.props
    if(typeof(session.jwt)=='undefined') {
      sessionStorage.removeItem('jwt');
      this.setState({session: null});
      this.props.history.push({pathname: '/login'});
      window.location.href="/login";
    }
    */
  }

  handleQueryChange(event) {
    console.log('handleQueryChange');
    console.log(event.target.value)
    var search = {}
    search['search[name]'] = encodeURIComponent(event.target.value);
    this.setState({search: search});
  }
  
  handleFindBanks = async (e) => {
    // e.preventDefault();
    console.log('handleFindBanks');
    console.log(this.state.search);
    if(this.state.search && this.state.search.toString().length>1) {
      this.setState({ loading: true });
      var params = {}
      params.bankPage = true;

      params.per_page = this.state.perPage;
      if(this.state.sort) {
        Object.assign(params, this.state.sort)
      }
      if(this.state.search) {
        Object.assign(params, this.state.search)
      }
      store.dispatch(getBanks(params)).then((result) => {
        this.setState({
          loading: false,
          data: result.payload.items,
          totalRows: result.payload.meta.total_items
      });
      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
        this.setState({
          error: error,
          modalOpen: true
        });
      }); 
    }
  }

  handlePageChange = async page => {
    console.log('handlePageChange');
    this.setState({ loading: true });
    const { perPage } = this.state;
    var params = {}
    params.per_page = perPage;
    params.page = page;
    params.bankPage = true;

    if(this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getBanks(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    }); 

  }

  handlePerRowsChange = async (perPage, page) => {
    console.log('handlePerRowsChange');
    this.setState({ loading: true });
    var params = {}
    params.per_page = perPage;
    params.page = page;
    params.bankPage = true;

    if(this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getBanks(params)).then((result) => {
      console.log('getBanks(params)');
      console.log(result.payload);
      this.setState({
        loading: false,
        data: result.payload.items,
        perPage: perPage,
    });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    }); 
  }

  handleSort = async (column, sortDirection) => {
    console.log('handleSort');
    this.setState({ loading: true });
    var params = {}
    params.per_page = this.state.perPage;
    params.bankPage = true;

    var sort = {}
    sort['order['+column.selector+']'] = sortDirection;
    Object.assign(params, sort)
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getBanks(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        sort: sort
    });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  };
  
  handleEditClick(event) {
    console.log('handleEditClick');
    this.props.history.push({pathname: '/banks/edit/'+event.target.id});
  }

  handleShowClick(event) {
    console.log('handleShowClick');
     store.dispatch(getBank(event.target.id)).then((result) => {
      if(typeof(result)!='undefined') {
        console.log(result);
        var bank = result.payload; 
        this.setState({
          bank: bank,
          modalTitle: bank.name
        });
        this.setState({modalOpen: true});
      }
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    }); 

  }

  onSearchBanks = (params) => {
    console.log(params);
    params.bankPage = true;

    if(typeof params === 'object') {
      store.dispatch(getBanks({ 'search[name]': params.name === 'All banks' ? '' :  params.name})).then((result) => {
        this.setState({
          loading: false,
          data: result.payload.items,
          totalRows: result.payload.meta.total_items
      })})
    } else {
      store.dispatch(getBanks({ 'search[name]':params ===  'All banks' ? '' :  params })).then((result) => {
        this.setState({
          loading: false,
          sortData: result.payload.items,
          ...(params ===  'All banks' && { data: result.payload.items} ),
          totalRows: result.payload.meta.total_items
      })})
    }
  
  }
  

  onSearchInputChange = (evt,field) => {

    const search = Object.assign({}, this.state.search);
    if(typeof(field)!='undefined') {
      if(field=='active') {
        if(evt=='Active') {
          var evt = {
            target: {name: 'active', value: '', checked: true}
          }
        }
        if(evt=='Inactive') {
          var evt = {
            target: {name: 'active', value: '', checked: false}
          }
        }
        if(evt=='Status') {
          var evt = {
            target: {name: 'active', value: '', checked: null}
          }
        }
      }
    }

    if(field === 'access') {
      var evt = {
        target: {name: 'access', value: evt.toLowerCase()}
      }
    }

    if(field === 'onlineplus') {
      var evt = {
        target: {name: 'onlineplus', value: evt.toLowerCase()}
      }
    }

    if(field === 'regioplus') {
      var evt = {
        target: {name: 'regioplus', value: evt.toLowerCase()}
      }
    }


    if(evt.target.value!='') {
      search['search['+evt.target.name+']'] = encodeURIComponent(evt.target.value);
    }
    else if(evt.target.name=='active' && evt.target.checked!=null) {
      search['search['+evt.target.name+']'] = evt.target.checked;
    }
     else if(evt.target.name=='active' && evt.target.checked==null) {
      delete search['search['+evt.target.name+']'];
    }
    else {
      delete search['search['+evt.target.name+']'];
    }
    this.setState({search});

    setTimeout(() => this.handleFindBanks(evt))
  }

  handleModalOpen() {
    this.setState({modalOpen: true});
  }

  handleModalClose() {
    this.setState({modalOpen: false})
  }

  render() {
    
    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

    const { loading, data, totalRows, sortData = []} = this.state;
    console.log('DATA: ', data)
  

    const handleChange = (state) => {
      // You can use setState or dispatch with something like Redux so we can use the retrieved data
      console.log('Selected Rows: ', state.selectedRows);
    };
      
    return (
      <div className="animated fadeIn mt-5">
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          id="modal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalOpen}>
            <div className="material-modal-content">
              <h4 id="transition-modal-title">{this.state.modalTitle} <i className="cil-x cursor-pointer float-right" onClick={this.handleModalClose}></i></h4>
              <div id="transition-modal-description">
                {this.state.error!=null
                  ? <div>{this.state.error.toString()}</div>
                  : <BankShow bank={this.state.bank} />
                }  
              </div>
            </div>
          </Fade>
        </Modal>
        <Row>
          <Col xs="12" lg="12">
            <div className="d-flex mb-4 justify-content-end">
              <Link to="/banks/new">
              <Button color="secondary" className="bg-white text-dark" outline>
                <i className="cil-plus text-dark"></i>
              </Button>
            </Link>
            </div>  
          </Col>
          <Col xs="12" lg="12">
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center justify-content-between">
                  <div><i className="fa fa-align-justify"></i> Banks list</div>
                  <div>
                    <Form className="form-inline float-right" onSubmit={this.handleFindBanks}>
                      {/*<Input type="search" name="name" className="mr-2" placeholder="Name" onChange={this.onSearchInputChange} />*/}

                      {/*BankCombobox*/}
                      <Combobox
                              name="brand"
                              type="select"
                              data={['All banks'].concat(sortData)}
                              valueField="id"
                              textField="name"
                              filter={false}
                              onChange={this.onSearchBanks}
                              disabled={[{id:0, name: '...'}]}
                              placeholder="All Banks"
                              asterisk="*"
                            />

                      <DropdownList
                        name="active"
                        onChange={(e)=>this.onSearchInputChange(e, 'active')}
                        data={['Status', 'Active', 'Inactive']}
                        placeholder="Status"
                        className="status-dropdown mr-3"
                      />

                      <a onClick={(e)=>this.handleFindBanks(e)} className="search-btn ml-1 cursor-pointer btn btn-outline-secondary"><i className="icon-magnifier"></i></a>  
                    </Form>
                  </div>
                </div>
              </CardHeader>
              <CardBody style={{minHeight: 500}}>
                  <DataTable
                    noHeader={false}
                    columns={columns(this.handleEditClick, this.handleShowClick)}
                    onSelectedRowsChange={this.updateState}
                    data={data}
                    theme="solarized"
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    onSort={this.handleSort}
                    sortServer
                    selectableRows
                    selectableRowsComponent={MaterialCheckbox}
                    selectableRowsComponentProps={selectProps}
                    onSelectedRowsChange={handleChange}
                  />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

Banks.propTypes = {
  session: PropTypes.any,
  accounts: PropTypes.bool
}

function mapStateToProps(state) {
  console.log('STATE')
    return {
      //banks: state.banks,
      //data: state.banks.banks.items,
      session: state.session,
      accounts: state.accounts
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({getBank, getBanks, checkAuth}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Banks)