import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes, array, bool } from 'prop-types';
import { Form, Input, Button, Badge, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialCheckbox from '../../shared/components/material-checkbox/MaterialCheckbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SimpleLineIcon from 'react-simple-line-icons';
import BrandCombobox from '../../shared/components/searchable-field/BrandCombobox';
import DataTableProgressComponent from '../../shared/components/widget/DataTableProgressComponent';
import DataTableNoDataComponent from '../../shared/components/widget/DataTableNoDataComponent';
import DropdownList from 'react-widgets/lib/DropdownList';
import 'react-widgets/dist/css/react-widgets.css';
import Combobox from 'react-widgets/lib/Combobox';
import { debounce } from 'lodash';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import './Modal.css';
import './Reviews.css';
import ReviewShow from './ReviewShow';

import memoize from 'memoize-one';
import DataTable, { createTheme } from 'react-data-table-component';

import { checkAuth } from '../../actions/sessionActions';
import { getReview, getReviews, deleteReview } from '../../actions/reviewActions';
import { getBanks,getBanksList } from '../../actions/bankActions';

import { SubmissionError } from 'redux-form'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Redux Store
import configureStore from '../../shared/redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

const columns = memoize((handleEditClick, handleShowClick, handleDeleteClick) => [
  {
    name: 'ID',
    selector: 'id',
    sortable: false,
    center: true,
    grow: 0
  },
  {
    name: 'Created at',
    selector: "created_at",
    sortable: false,
    cell: row => <div>{row.created_at != null ? row.created_at.substring(0, 10) : ''}</div>,
    grow: 1
  },
  {
    name: 'Brand',
    selector: "brand",
    sortable: true,
    cell: row => <div>{row.brand != null ? row.brand.name : '---'}</div>,
    grow: 1
  },
  {
    name: 'Status',
    selector: 'active',
    sortable: false,
    cell: row => <span>{row.active == true ? 'Active' : 'Inactive'}</span>,
    center: false,
    grow: 1
  },
  {
    name: 'Title',
    selector: 'title',
    sortable: false,
    grow: 1
  },
  {
    name: 'Rating',
    selector: 'rating',
    sortable: true,
    grow: 0
  },
  {
    name: 'Actions',
    sortable: false,
    left: true,
    cell: (row) => <div>
      <button className="btn btn-sm btn-light mr-2" onClick={handleEditClick} id={row.id}><i className="icon-pencil" onClick={handleEditClick} id={row.id}></i></button>
      <button className="btn btn-sm btn-light mr-2" onClick={handleDeleteClick} id={row.id}><i className="icon-trash" id={row.id}></i></button>
      <button className="btn btn-sm btn-light mr-2" onClick={handleShowClick} id={row.id}><i className="icon-book-open" onClick={handleShowClick} id={row.id}></i></button>
    </div>,
    ignoreRowClick: true
  }
]);


const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

class Reviews extends Component {

  constructor(props) {
    super(props);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleShowClick = this.handleShowClick.bind(this);
    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.onSearchBanks = debounce(this.onSearchBanks.bind(this), 300);

    this.handleBankChange = this.handleBankChange.bind(this);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.onSearchableInputChange = this.onSearchableInputChange.bind(this);
    var bankId = ((new URLSearchParams(window.location.search)).get("bank_id"));
    this.state = {
      data: [],
      loading: false,
      totalRows: 0,
      perPage: 10,
      sort: false,
      search: null,
      modalOpen: false,
      review: null,
      modalTitle: null,
      error: null,
      banks: [],
      selectedBank: bankId == null ? null : parseInt(bankId),
      showSearch: false
    };
  }

  componentDidMount(props) {
    this.setState({ loading: true });
    console.log('componentDidMount');
    console.log('---accounts')
    console.log(this.props.accounts);
    this.setState({ loading: false, data: [] });
          getBanksList().then(({ items }) => this.setState(item => ({ ...item, banks: items })));
   
    if (this.state.selectedBank != null) {
      this.setState({ showSearch: true }, function () {
        this.fetchReviews();
      });
    }
    /*
    const { perPage } = this.state;
    console.log(perPage);
    var params = {}
    params.per_page = perPage;
    store.dispatch(getReviews(params)).then((result) => {
      this.setState({
        data: result.payload.items,
        totalRows: result.payload.meta.total_items,
        loading: false,
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalTitle: 'Error!',
        modalOpen: true
      });
    });
    */


    /* JWT is stored in memory */
    /*
    const {session} = this.props
    if(typeof(session.jwt)=='undefined') {
      sessionStorage.removeItem('jwt');
      this.setState({session: null});
      this.props.history.push({pathname: '/login'});
      window.location.href="/login";
    }
    */
  }

  handleQueryChange(event) {
    console.log('handleQueryChange');
    console.log(event.target.value)
    var search = {}
    search['search[brand]'] = encodeURIComponent(event.target.value);
    this.setState({ search: search });
  }

  handleFindReviews = async (e) => {
    e.preventDefault();
    console.log('handleFindReviews');
    console.log(this.state.search);
    if (this.state.search && this.state.search.toString().length > 1) {
      this.setState({ loading: true });
      var params = {}
      params.per_page = this.state.perPage;
      params.bank_id = this.state.selectedBank;
      if (this.state.sort) {
        Object.assign(params, this.state.sort)
      }
      if (this.state.search) {
        Object.assign(params, this.state.search)
      }
      store.dispatch(getReviews(params)).then((result) => {
        this.setState({
          loading: false,
          data: result.payload.items,
          totalRows: result.payload.meta.total_items
        });
      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
        this.setState({
          error: error,
          modalOpen: true
        });
      });
    }
  }

   onSearchBanks = (value) => {
      if (typeof value === 'object') {
        this.handleBankChange(value);
      } else {
        getBanksList({ 'search[name]': value }).then(({ items }) => {
          if (JSON.stringify(this.state.banks) !== JSON.stringify(items)) {
            this.setState({ banks: items });
          }
        });
      }
    }

  handlePageChange = async page => {
    console.log('handlePageChange');
    this.setState({ loading: true });
    const { perPage } = this.state;
    var params = {}
    params.per_page = perPage;
    params.page = page;
    params.bank_id = this.state.selectedBank;
    if (this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if (this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getReviews(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });

  }

  handlePerRowsChange = async (perPage, page) => {
    console.log('handlePerRowsChange');
    this.setState({ loading: true });
    var params = {}
    params.per_page = perPage;
    params.page = page;
    params.bank_id = this.state.selectedBank;
    if (this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if (this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getReviews(params)).then((result) => {
      console.log('getReviews(params)');
      console.log(result.payload);
      this.setState({
        loading: false,
        data: result.payload.items,
        perPage: perPage,
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleSort = async (column, sortDirection) => {
    console.log('handleSort');
    this.setState({ loading: true });
    var params = {}
    params.per_page = this.state.perPage;
    params.bank_id = this.state.selectedBank;
    var sort = {}
    sort['order[' + column.selector + ']'] = sortDirection;
    Object.assign(params, sort)
    if (this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getReviews(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        sort: sort
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  };

  handleEditClick(event) {
    console.log('handleEditClick');
    this.props.history.push({
      pathname: '/reviews/edit/' + event.target.id + '/' + this.state.selectedBank,
      search: "?" + new URLSearchParams({ bank_id: this.state.selectedBank }).toString()
    });
  }

  handleShowClick(event) {
    console.log('handleShowClick');
    store.dispatch(getReview({ id: event.target.id, bank_id: this.state.selectedBank })).then((result) => {
      if (typeof (result) != 'undefined') {
        console.log(result);
        var review = result.payload;
        this.setState({
          review: review,
          modalTitle: review.title
        });
        this.setState({ modalOpen: true });
      }
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleDeleteClick(event) {
    console.log('handleDeleteClick');
    if (window.confirm('Are you sure you want to delete this review?')) {
      store.dispatch(deleteReview(this.state.selectedBank, event.target.id)).then((result) => {
        if (typeof (result) != 'undefined') {
          var review = result.payload;
          window.location.reload();
        }
      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
        this.setState({
          error: error,
          modalOpen: true
        });
      });
    }
  }

  onSearchInputChange = (evt, field) => {
    console.log('---evt')
    console.log(evt);
    const search = Object.assign({}, this.state.search);
    if (typeof (field) != 'undefined') {
      if (field == 'status') {
        if (evt == 'Active') {
          var evt = {
            target: { name: 'active', value: '', checked: true }
          }
        }
        else if (evt == 'Inactive') {
          var evt = {
            target: { name: 'active', value: '', checked: false }
          }
        }
        else {
          var evt = {
            target: { name: 'active', value: '', checked: null }
          }
        }
      }
    }
    if (evt.target.value != '') {
      search['search[' + evt.target.name + ']'] = encodeURIComponent(evt.target.value);
    }
    else if (evt.target.name == 'active' && evt.target.checked != null) {
      search['search[' + evt.target.name + ']'] = evt.target.checked;
    }
    else if (evt.target.name == 'active' && evt.target.checked == null) {
      delete search['search[' + evt.target.name + ']'];
    }
    else {
      delete search['search[' + evt.target.name + ']'];
    }
    this.setState({ search });
  }

  onSearchableInputChange(name, value) {
    console.log('onSearchableInputChange');
    const search = Object.assign({}, this.state.search);
    if (value != '') {
      search['search[' + name + ']'] = encodeURIComponent(value);
    }
    else if (name == 'active') {
      search['search[' + name + ']'] = value; // true/false
    }
    else {
      delete search['search[' + name + ']'];
    }
    this.setState({ search });
  };

  handleModalOpen() {
    this.setState({ modalOpen: true });
  }

  handleModalClose() {
    this.setState({ modalOpen: false })
  }

  handleBankChange(e) {
    console.log('---handleBankChange');
    if (e.id != '' && e.id > 0) {
      this.setState({ selectedBank: e.id, showSearch: true }, function () {
        this.fetchReviews();
      });
      this.props.history.push({
        pathname: '/reviews/reviews/' + e.id,
        search: "?" + new URLSearchParams({ bank_id: e.id }).toString()
      });
    }
    else {
      this.setState({
        data: [],
        loading: false,
        totalRows: 0,
        perPage: 10,
        sort: false,
        search: null,
        showSearch: false,
        selectedBank: null
      });
    }
  }

  fetchReviews() {
    var params = {}
    params.per_page = this.state.perPage;
    params.bank_id = this.state.selectedBank;
    var sort = {}
    if (this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if (this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getReviews(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        sort: sort
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  render() {

    console.log('---render');

    const session = this.props.session
    if (typeof (session.forceLogout) != "undefined" && session.forceLogout == 1) {
      this.setState({ session: null });
      window.location.href = "/login";
    }

    const { loading, data, totalRows } = this.state;
    const { banks } = this.props;

    const handleChange = (state) => {
      // You can use setState or dispatch with something like Redux so we can use the retrieved data
      console.log('Selected Rows: ', state.selectedRows);
    };

    const banksArr = this.state.banks.map(bank => ({ id: bank.id, name: bank.name }));

    return (
      <div className="animated fadeIn mt-5">
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          id="modal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalOpen}>
            <div className="material-modal-content">
              <h4 id="transition-modal-title">{this.state.modalTitle} <i className="cil-x cursor-pointer float-right" onClick={this.handleModalClose}></i></h4>
              <div id="transition-modal-description">
                {this.state.error != null
                  ? <div>{this.state.error.toString()}</div>
                  : <ReviewShow review={this.state.review} />
                }
              </div>
            </div>
          </Fade>
        </Modal>
        <Row>
          <Col xs="12" lg="2">
            <Combobox
              placeholder="Choose Bank"
              data={[].concat(banksArr)}
              valueField="id"
              textField="name"
              defaultValue={{ id: this.state.selectedBank != null ? this.state.selectedBank : 0, name: (this.state.selectedBank != null && banksArr?.length > 0) ? banksArr?.find(b => b.id == this.state.selectedBank)?.name : '' }}
              type="select"
              filter={false}
              asterisk="*"
              className="mb-4"
              onChange={this.onSearchBanks}
            />
          </Col>
          <Col xs="12" lg="10">
            {/*<div className="d-flex mb-4 justify-content-end">
              <Link to="/reviews/new">
                <Button color="secondary" className="bg-white text-dark" outline>
                  <i className="cil-plus text-dark"></i>
                </Button>
              </Link>
            </div>
            */}
          </Col>
          <Col xs="12" lg="12">
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center justify-content-between">
                  <div><i className="fa fa-align-justify"></i> Reviews list</div>
                  {this.state.showSearch &&
                    <div>
                      <Form className="form-inline float-right text-right" onSubmit={this.handleFindReviews}>
                        <BrandCombobox onChange={this.onSearchableInputChange} className="w-200" />
                        <DropdownList
                          name="status"
                          onChange={(e) => this.onSearchInputChange(e, 'status')}
                          data={['All statuses', 'Active', 'Inactive']}
                          placeholder="Status"
                          className="status-dropdown text-left"
                        />
                        <a onClick={(e) => this.handleFindReviews(e)} className="search-btn ml-1 cursor-pointer btn btn-outline-secondary"><i className="icon-magnifier"></i></a>
                      </Form>
                    </div>
                  }
                </div>
              </CardHeader>
              <CardBody>
                <DataTable
                  noHeader={false}
                  columns={columns(this.handleEditClick, this.handleShowClick, this.handleDeleteClick)}
                  onSelectedRowsChange={this.updateState}
                  data={data}
                  theme="solarized"
                  progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  onSort={this.handleSort}
                  sortServer
                  selectableRows
                  selectableRowsComponent={MaterialCheckbox}
                  selectableRowsComponentProps={selectProps}
                  onSelectedRowsChange={handleChange}
                  progressComponent={<DataTableProgressComponent />}
                  noDataComponent={<DataTableNoDataComponent />}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

Reviews.propTypes = {
  data: PropTypes.array,
  banks: PropTypes.array,
  session: PropTypes.any
}

function mapStateToProps(state) {
  console.log('STATE')
  console.log(state);
  return {
    //reviews: state.reviews,
    data: (typeof (state.reviews) !== 'undefined' && typeof (state.reviews.result) !== 'undefined') ? state.reviews.result.items : [],
    banks: (typeof (state.banks) !== 'undefined' && typeof (state.banks.result) !== 'undefined') ? state.banks.result.items : [],
    session: state.session
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({ getReview, getReviews, getBanks, checkAuth }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reviews)